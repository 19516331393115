import '../styles/globals.scss'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { useEffect, useState,  } from 'react'
import 'tailwindcss/tailwind.css'
import { ThemeProvider} from '../context/ThemeContext';
export default function App({ Component, pageProps }: AppProps) {
  const [showChild,setShowChild] = useState(false)

  useEffect(()=>{
    setShowChild(true)
  },[])

  if(!showChild){
    return null
  }
  return ( 
    <ThemeProvider>
    <Head>
    <title>Eagle码力一哥</title>
      <meta name="description" content="Eagle 是一个帮助开发人员分析代码库和项目结构的 AI Agent。它能够深入阅读和理解程序代码文件，识别关键功能，并提供详细的解释和改进建议。
" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {/* <link rel="icon" href="/favicon.ico" /> */}
    </Head>
    <Component {...pageProps} />
  </ThemeProvider>
)
 
}
