// context/ThemeContext.js
import { createContext, useContext, useState, useEffect } from 'react';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('dark'); // 默认主题为 light

  useEffect(() => {
    // 检查本地存储中的主题并应用
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      setTheme(savedTheme);
    }
  }, []);

  useEffect(() => {
   // debugger
    const root = document.documentElement;
    if (theme === 'light') {
      root.style.setProperty('--primary-color', '#000000');
      root.style.setProperty('--background-color', '#ECF0FF');
      root.style.setProperty('--login-logo-color', '#005BD8');
      root.style.setProperty('--login-right-bg', '#ffffff');
      root.style.setProperty('--login-right-title', '#222222');
      root.style.setProperty('--login-form-bg', '#F6F7FB');
      root.style.setProperty('--login-remember-text', '#222222');
      root.style.setProperty('--login-form-placeholder', '#8E8E8E');
      root.style.setProperty('--slider-logo', '#005BD8');
      root.style.setProperty('--slider-text', '#222222');
      root.style.setProperty('--slider-bg', '#ffffff');
      root.style.setProperty('--slider-line', '#E7E7E7');
      root.style.setProperty('--chat-bg', '#F6F7FB');
      root.style.setProperty('--chat-left-bg', '#ffffff');
      root.style.setProperty('--chat-left-text', '#222222');
      root.style.setProperty('--chat-tips-text', '#222222');
      root.style.setProperty('--chat-tips-bg', '#9DC6FF');
      root.style.setProperty('--chat-send-bg', '#ffffff');
      root.style.setProperty('--setting-disabled-bg', '#F6F6F6');
      root.style.setProperty('--setting-input-bg', '#ffffff');
      root.style.setProperty('--setting-table-text', '#222222');
      root.style.setProperty('--setting-table-border', '#E7E7E7');

    } else {
      root.style.setProperty('--primary-color', '#ffffff');
      root.style.setProperty('--background-color', '#0F040A');
      root.style.setProperty('--login-logo-color', '#86ADE8');
      root.style.setProperty('--login-right-bg', '#000000');
      root.style.setProperty('--login-right-title', '#ffffff');
      root.style.setProperty('--login-remember-text', '#F2F4F8');
      root.style.setProperty('--login-form-bg', '#222222');
      root.style.setProperty('--login-form-placeholder', '#E0E0E0');
      root.style.setProperty('--slider-logo', '#ffffff');
      root.style.setProperty('--slider-text', '#ffffff');
      root.style.setProperty('--slider-bg', '#1D1D1C ');
      root.style.setProperty('--slider-line', '#CDCDCD');
      root.style.setProperty('--chat-bg', '#282828');
      root.style.setProperty('--chat-left-bg', '#282828');
      root.style.setProperty('--chat-left-text', '#ffffff');
      root.style.setProperty('--chat-tips-text', '#D9D9D9');
      root.style.setProperty('--chat-tips-bg', '#9DC6FF');
      root.style.setProperty('--chat-send-bg', '#3f3f3f');
      root.style.setProperty('--setting-disabled-bg', '#7D7D7D');
      root.style.setProperty('--setting-input-bg', '#222222');
      root.style.setProperty('--setting-table-text', '#D6D6D7');
      root.style.setProperty('--setting-table-border', '#535353');
    }

    // 保存到本地存储
    localStorage.setItem('theme', theme);
  }, [theme]);

  const toggleTheme = (theme) => {
   // debugger
    setTheme(theme);
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
